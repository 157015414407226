<template>
  <div>
    <div class="modals">

    </div>
    <div class="row">
      <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mb-3">
        <div class="d-flex w-100">
          <base-input class="d-inline-block" v-model="request.api.firstName"
                      label="First Name"
                      placeholder="Search..." @change="filterChanged" type="search" @search="filterChanged">
          </base-input>
          <base-input class="d-inline-block ml-2" v-model="request.api.lastName"
                      label="Last Name"
                      placeholder="Search..." @change="filterChanged" type="search" @search="filterChanged">
          </base-input>

          <base-input label="Completed By" class="d-inline-block ml-2">
            <p @click="clearCompletedBy" class="d-inline-block float-right mt--4 mr-2 mb-0 clear-txt">Clear</p>
            <el-select
              class="select-primary pagination-select"
              v-model=request.api.completedBy
              @change="filterChanged"
            >
              <el-option
                class="select-primary"
                key="All"
                label="All"
                value=""
              >
              </el-option>
              <el-option
                class="select-primary"
                v-for="(item , index) in response.completedByOptions"
                v-if="item.label!=='Incomplete'"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </base-input>

          <base-input label="Current Stage" class="d-inline-block ml-2">
            <p @click="clearStage" class="d-inline-block float-right mt--4 mr-2 mb-0 clear-txt">Clear</p>
            <el-select
              class="select-primary pagination-select"
              v-model=request.api.currentStage
              placeholder="Current Stage"
              @change="filterChanged"
            >
              <el-option
                class="select-primary"
                v-for="(stage , index) in response.stages"
                :key="`${stage.label}_${stage.value}`"
                :label="stage.label"
                :value="`${stage.value}`"
              >
              </el-option>
            </el-select>
          </base-input>

          <base-button type="secondary"
                       class="d-inline-block pt-2 pb-2 ml-auto font-weight-normal custom-button align-self-center filter-button-width"
                       @click="$store.commit('showUCFS')">
            <img class="mr-2" src="/img/svg/filters.svg" alt="Filters"> Filter <el-badge :value="getFiltersCount" class="filter-count-badge" type="warning"></el-badge>
          </base-button>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex">
        <div class="table-responsive">
          <el-table :data="response.tblChecklistPatientsData"
                    row-key="id"
                    v-loading="loading"
                    class="table-responsive table-flush table-stalled-patient"
                    header-row-class-name="thead-light"
          >
            <el-table-column label="PATIENT NAME" min-width="110px" max-width="200px">
              <template slot-scope="scope">
                <el-tooltip
                  class="item" effect="dark"
                  :content="hoverPatientInfo(scope.row)"
                  placement="bottom-start"
                  :visible-arrow="false"
                >
                  <router-link :to="{name: 'checklists', params: {id: scope.row.user_id}}">
                    <span class="color-maroon">{{ scope.row.patient.first_name + ' ' + scope.row.patient.last_name }}</span>
                  </router-link>
                </el-tooltip>
              </template>
            </el-table-column>

            <el-table-column label="CHECKLIST ITEM" prop="todo_name"  min-width="200px">
            </el-table-column>
            <el-table-column label="Completed On" prop="is_completed_date" min-width="200px" >
              <template slot-scope="scope">
                {{
                  formatDate(scope.row.is_completed_date)
                }}
              </template>
            </el-table-column>
            <el-table-column label="Completed By" min-width="200px" >
              <template slot-scope="scope">
                <el-select
                  class="select-primary"
                  v-model=scope.row.checked_by
                  @change="onChangeCompletedBy(scope.row)"
                >
                  <el-option
                    class="select-primary"
                    v-for="(item) in response.completedByOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <seminar-surgery-footer :pagination="pagination" @changePage="changePage" @changedPageLength="changedPageLength"/>
      </div>
    </div>
  </div>
</template>

<script>
import SeminarSurgeryFooter from "./SeminarSurgeryFooter";
import {Option, Select, Table, TableColumn} from "element-ui";
import Helper from "@/util/globalHelpers";
import moment from "moment";

export default {
  name: "ChecklistPatients",
  props :  ['tab'],
  components: {
    SeminarSurgeryFooter,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data(){
    return {
      loading : false,
      modals : {
      },
      request : {
        api: {
          patientId:'',
          firstName:'',
          lastName:'',
          currentStage:'',
          completedBy:'Patient',
          surgeryDate: '',
          surgeryDateDuration: '',
        },
      },
      response : {
        tblChecklistPatientsData : [],
        stages: [],
        completedByOptions: [],
        categories: [],
        defaultCategories: [],
      },
      pagination : {
        currentPage : 1,
        perPage : 20,
        page:1,
        lastPage:1,
      },
      filtersCount : 0,
    }
  },
  activated() {
    this.getFiltersData();
    let vm = this;
    this.$root.$on('UserSubCategoryFilterCount',(data)=>{
      vm.filtersCount = data;
    });
    this.$root.$on('applyUserSubCategoryFilters',(data)=>{
      vm.filtersCount = data.filtersCount;
      vm.request.api.surgeryDate = data.surgeryDate;
      vm.request.api.surgeryDateDuration = data.surgeryDateDuration;
      vm.getChecklistPatients(data.obj);
    });
  },
  deactivated() {
    this.$root.$off('UserSubCategoryFilterCount');
    this.$root.$off('applyUserSubCategoryFilters');
  },
  computed: {
    getFiltersCount () {
      return this.filtersCount ?? 0;
    }
  },
  methods : {

    hoverPatientInfo(row){
      let returnable = ''
      returnable += (row.patient.hasOwnProperty('first_name') === true) ? row.patient.first_name : ''
      returnable += (row.patient.hasOwnProperty('last_name') === true) ? ' ' + row.patient.last_name : ''
      returnable = (row.patient.hasOwnProperty('email') === true && row.patient.email !== '' && row.patient.email != null) ? returnable+', '+row.patient.email : returnable+''
      returnable = (row.patient.hasOwnProperty('phone') === true && row.patient.phone !== '' && row.patient.phone != null) ? returnable+', '+row.patient.phone : returnable+''
      if (row.patient.hasOwnProperty('dob') === true && row.patient.dob !== '' && row.patient.dob != null){
        if (!isNaN(Date.parse(row.patient.dob))){
          const date = new Date(row.patient.dob)
          returnable = returnable+', '+Helper.extractAndFormatDate(date.toDateString(),false)
        }
      }else {
        returnable = returnable+''
      }
      returnable = (row.patient.hasOwnProperty('mrn_number') === true && row.patient.mrn_number !== '' && row.patient.mrn_number != null) ? returnable + ', ' + row.patient.mrn_number : returnable + ' '
      return returnable
    },

    getFiltersData(){
      let vm = this;
      vm.loading = true;
      axios.get(this.$store.getters.getBaseUrl + '/api/seminar2surgery/filters-data?tab=3'
      ).then((response) => {
        vm.request.api.currentStage = response.data.data.default_stage ? response.data.data.default_stage : '50';
        vm.response.stages = response.data.data.stages;
        vm.response.completedByOptions = response.data.data.checkedBy;
        vm.response.categories = response.data.data.categories;
        vm.response.defaultCategories = response.data.data.defaultCategories;
        this.$store.commit('setUserSubcategories',{categories:vm.response.categories,defaultCategories:vm.response.defaultCategories })
        let obj = [];
        vm.response.categories.forEach((category,index)=>{
          var i = vm.response.defaultCategories.findIndex(dc=>{
            if(dc.user_category_id == category.id){
              return true;
            }
          });
          if(i!=-1){
            obj.push(vm.response.defaultCategories[i].user_sub_category_id);
          }
        });
          vm.getChecklistPatients(obj);
      }).catch(error => {
        console.log(error.response)
      });
    },

    filterChanged(){
      this.pagination.page = 1;
      this.createCategoriesArrAndApiCall();
    },

    clearStage(){
      this.request.api.currentStage = '50';
      this.pagination.page = 1;
      this.createCategoriesArrAndApiCall();
    },

    clearCompletedBy(){
      this.request.api.completedBy = '';
      this.pagination.page = 1;
      this.createCategoriesArrAndApiCall();
    },

    clearUserCategories(){
      this.createCategoriesArrAndApiCall();
    },

    changePage(page){
      if(this.pagination.page != page){
        this.pagination.page = page;
        this.createCategoriesArrAndApiCall();
      }
    },

    changedPageLength(pageLength) {
      this.pagination.perPage = pageLength;
      this.createCategoriesArrAndApiCall();
    },

    createCategoriesArrAndApiCall(){
      let userSubCategoriesIds = [];
      this.response.categories.forEach(category => {
        if(category.state && !isNaN(category.state)){
          userSubCategoriesIds.push(category.state);
        }
      });
      this.getChecklistPatients(userSubCategoriesIds);
    },

    getChecklistPatients(userSubCategories = null){
      let vm = this;
      vm.loading = true;
      axios.get(this.$store.getters.getBaseUrl + '/api/seminar2surgery/checklist-patients' ,{
          params : { 
              ...vm.request.api,
              user_sub_categories: userSubCategories && userSubCategories.length > 0 ? userSubCategories : null,
              page: vm.pagination.page,
              perPage: vm.pagination.perPage
            }
        }
      ).then((response) => {
        vm.response.tblChecklistPatientsData = [];
        vm.response.tblChecklistPatientsData = [...response.data.data.data];
        vm.pagination.currentPage = response.data.data.current_page;
        vm.pagination.lastPage = response.data.data.last_page;
        vm.pagination.perPage = response.data.data.per_page;
        vm.pagination.page = response.data.data.current_page;
        vm.pagination.total = response.data.data.total;
        vm.loading = false;
      }).catch(error => {
        console.log(error.response);
      });
    },

    onChangeCompletedBy(row){
      let vm = this;
      axios.post(`${this.$store.getters.getBaseUrl}/api/seminar2surgery/checked-by/update`,
        {
          checklistId:row.id,
          completedBy:row.checked_by,
        }
      )
        .then((response)=>{
          let message = response.data.message;
          vm.$notify.success({
            title : 'Seminar 2 Surgery',
            message: message
          });
          vm.getChecklistPatients();
        })
        .catch((error)=>{
          let message = 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title : 'Seminar 2 Surgery',
            message: message
          })
        });
    },

    formatDate(oldDate) {
      let date = '';
      if(oldDate){
        date = moment(oldDate).format('MMM D, YYYY');
      }
      return date;
    },
  },
  watch : {

  }
}
</script>

<style scoped>
.clear-txt {
  text-align: left;
  font: normal normal normal 9px/13px Open Sans;
  letter-spacing: 0px;
  color: #BB2255;
  cursor: pointer;
}

</style>
<style>


</style>
