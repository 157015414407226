<template>
  <div v-loading="loaders.reports">
    <export-checklist-report-modal @exportReport="exportAllPatientsReport"></export-checklist-report-modal>
    <div class="modals">
      <modal :show.sync="modals.exportReportModal" class="exportReportModal">
        <!-- Notification Modal Header -->
        <template slot="header">
          <h5 class="modal-title" id="SendReminderModal">Report</h5>
        </template>
        <!-- Notification Modal Header Finished -->

        <!-- Notification Modal Body -->
        <template slot="default">
          <p class="message-modal-text mb-4 export-report-message">
            This report can take a few minutes to load. Thank you for your patience.
          </p>
        </template>
        <!-- Notification Modal Body Finished -->

        <!-- Notification Modal Footer -->
        <template slot="footer">
          <div class="mt--5">
            <base-button class="btn-purple" type="secondary" @click="modals.exportReportModal=false">Cancel</base-button>
            <base-button class="btn-primary-action" type="success" @click="exportReports">Export</base-button>
          </div>
        </template>
        <!-- Notification Modal Footer Finished -->
      </modal>
    </div>
    <div class="m-0 pl-3 pt-4 pb-0 pr-2">
      <h1 class="ml-3 d-inline-block mt-2">Checklist Reports</h1>
      <base-button size="md" id="export"
                   v-if="showExportButton"
                   @click="toggleExportReportModal"
                   class="float-right mr-4 mt-2 white-outline-button button-font title-btn-padding"
                   type="secondary">
        <img alt="Image placeholder" class="mt--1" src="/img/svg/export.svg"> Export
      </base-button>
    </div>

    <base-header class="pb-6" style="height: 150px">
    </base-header>
    <div class="container-fluid mt--8 mb-5">
      <el-tabs
        class=""
        type="border-card"
        v-model="tab"
        @tab-click="tabClick"
      >
        <el-tab-pane label="% Complete">
          <p class="text-right disclaimer-text">Note: Data on this report is 1 day delayed.</p>
          <div v-if="tab == 0">
            <keep-alive>
              <complete
                :tab="tab"
                @exportComplete="loaders.reports = false"
                ref="completionRef"
              />
          </keep-alive>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Stalled Patients">
          <p class="text-right disclaimer-text">Note: Data on this report is 1 day delayed.</p>
          <div v-if="tab == 1">
            <keep-alive>
              <stalled-patients
                ref="stalledPatientsRef"
                @exportComplete="loaders.reports = false"
              />
            </keep-alive>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Checklists">
          <div v-if="tab == 2">
            <keep-alive>
              <checklist-patients
                ref="checklistPatientsRef"
              />
          </keep-alive>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Completed">
          <p class="text-right disclaimer-text">Note: Data on this report is 1 day delayed.</p>
          <div v-if="tab == 3">
            <keep-alive>
              <all-patients
                ref="allPatientsRef"
                @exportComplete="loaders.reports = false"
              />
            </keep-alive>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Lost Patients">
          <div v-if="tab == 4">
            <lost-patients
              @exportComplete="loaders.reports = false"
              ref="lostPatientsRef"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane label="Merge Patients">
          <div v-if="tab == 5">
            <merge-patients
              ref="mergePatientsRef"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="tempClassUCFS">
      <user-category-sidebar
        :show-surgery-date-filter="true"
        ref="userCategorySidebar"
      />
    </div>
  </div>
</template>
<script>

import moment from "moment";
import {Table, TableColumn, Select, Option} from 'element-ui';
import Complete from "./Complete";
import StalledPatients from "./StalledPatients";
import ChecklistPatients from "./ChecklistPatients";
import AllPatients from "./AllPatients";
import LostPatients from "./LostPatients";
import MergePatients from "./MergePatients";
import UserCategorySidebar from "../../Components/UserCategorySidebar";
import ExportChecklistReportModal from "@/views/Pages/Seminar2Surgery/ExportChecklistReportModal";

export default {
  components: { Complete, StalledPatients,ChecklistPatients,AllPatients,LostPatients,MergePatients,UserCategorySidebar,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    ExportChecklistReportModal
  },
  data() {
    return {
      loaders: {
        reports: false
      },
      modals: {
        exportReportModal:false,
      },
      request: {},
      response: {},
      tab: 0,
      showExportButton:true,
      showUserCategoryFilterSidebar:false,
      filters : {
        categories : [],
        defaultCategories :[],
      }
    };
  },
  created() {
  },
  methods: {
    tabClick() {
      let currentTab = parseInt(this.tab) + 1;
      let exportableTabs = [1, 2, 4, 5];
      if (exportableTabs.includes(currentTab)) {
        this.showExportButton = true;
      } else {
        this.showExportButton = false;
      }
      this.$refs.userCategorySidebar.clearSurgeryDateFilter();
    },

    toggleExportReportModal() {
      let currentTab = parseInt(this.tab) + 1;
      if ( currentTab == 4) {
        this.$store.commit('toggleExportChecklistReportModal', true)
      } else {
        this.modals.exportReportModal = !this.modals.exportReportModal;
      }
    },

    exportAllPatientsReport(reportType) {
      this.$store.commit('toggleExportChecklistReportModal', false)
      this.$refs.allPatientsRef.exportAllPatients(reportType)
      this.loaders.reports = true
    },

    exportReports() {
      let currentTab = parseInt(this.tab) + 1;
      if (currentTab == 1) {
        this.modals.exportReportModal = !this.modals.exportReportModal;
        this.$refs.completionRef.exportPercentageCompletion();
        this.loaders.reports = true
      } else if (currentTab == 2) {
        this.modals.exportReportModal = !this.modals.exportReportModal;
        this.$refs.stalledPatientsRef.exportStalledPatients();
        this.loaders.reports = true
      } else if (currentTab == 3) {

      } else if (currentTab === 4) {

      } else if (currentTab === 5) {
        this.modals.exportReportModal = !this.modals.exportReportModal
        this.$refs.lostPatientsRef.exportLostPatientsReport()
        this.loaders.reports = true
      } else if (currentTab === 6) {

      }
    },
  }
};
</script>
<style scoped>
  .disclaimer-text{
    font-size: 14px;
  }
</style>
<style>
.exportReportModal .modal-body {
  padding-top: 0px;
}

.exportReportModal .export-report-message {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #4D4D4D;
  opacity: 1;
}

.exportReportModal .stalled-patients-snooze-message {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #4D4D4D;
  opacity: 1;
}

.btn-purple {
  color: white;
  background-color: #8898aa;
  border-radius: 9px;
}
</style>
