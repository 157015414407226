<template>
  <div>
    <lost-patient-line-graph-modal
      ref="lostPatientLineGraph"
      :lost-patients="lostPatientsLineGraphData"
      :month-labels="lostPatientsLineGraphLabels"
      :loader="lostPatientGraphLoading"
      @get-lost-patients="fetchLostPatientsLineGraph"
    />
    <div class="modals">
      <modal :show.sync="modals.lostPatientsStatsModal" class="lost-patients-stats-modal">
        <template slot="header">
          <h5 class="modal-title">Lost Patients</h5>
        </template>
        <div class="row doughnut-chart-row">
          <template>
            <div class="content mt--4 ml--1"  v-if="showChart">
              <pie-chart
                id="pie-chart"
                :chart-data="pieChart.chartData"
                :extra-options="pieChart.extraOptions"
              >
              </pie-chart>
            </div>
            <div class=" col-md-3 content d-flex align-items-center justify-content-center"  v-else>
              <h4>No Data</h4>
            </div>
          </template>

          <div class="doughnut-labels-wrapper">
            <p class="doughnut-labels font-weight-600" id="breakfast-circle">Lost contact with patient</p>
            <p class="doughnut-labels font-weight-600" id="lunch-circle">Denied by insurance</p>
            <p class="doughnut-labels font-weight-600" id="dinner-circle">No longer wants to have weight loss surgery</p>
            <p class="doughnut-labels font-weight-600" id="snacks-circle">Unhappy with program</p>
            <p class="doughnut-labels font-weight-600" id="not-meet-criteria-circle">Does not meet criteria</p>
            <p class="doughnut-labels font-weight-600" id="network-out-circle">Out of network insurance</p>
            <p class="doughnut-labels font-weight-600" id="others-circle">Other</p>
          </div>
        </div>

      </modal>
    </div>
    <div class="row">
      <div class="col-9 d-flex justify-content-center justify-content-sm-between flex-wrap mb-3">
        <div class="d-inline-block">
          <base-input class="d-inline-block" v-model="request.api.firstName"
                      label="First Name"
                      placeholder="Search..." @change="filterChanged" type="search" @search="filterChanged">
          </base-input>
          <base-input class="d-inline-block ml-2" v-model="request.api.lastName"
                      label="Last Name"
                      placeholder="Search..." @change="filterChanged" type="search" @search="filterChanged">
          </base-input>

        </div>
      </div>
      <div class="col-3">
        <base-button
          @click="toggleLostPatientsStatsModal"
          class="font-weight-normal btn-lost-patients-modal"
          type="secondary">
          <img alt="Image placeholder" class="ico-btn-lost-patients-modal" src="/img/svg/stats.svg"> View Stats
        </base-button>
        <base-button
          @click="showLostPatientsLineGraphModal"
          class="font-weight-normal btn-lost-patient-line-graph mr-1"
          type="secondary">
          Line Graph
        </base-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex">
        <div class="table-responsive">
          <el-table :data="response.tblLostPatients"
                    v-loading="loading"
                    row-key="id"
                    class="table-responsive table-flush"
                    header-row-class-name="thead-light"
                    @sort-change="sortMethod"
          >
            <el-table-column label="PATIENT NAME" min-width="140px" >
              <template slot-scope="scope">
                <el-tooltip
                  class="item" effect="dark"
                  :content="hoverPatientInfo(scope.row)"
                  placement="bottom-start"
                  :visible-arrow="false"
                >
                  <router-link :to="{name: 'checklists', params: {id: scope.row.patient_id}}">
                    <span class="color-maroon">{{ scope.row.first_name + ' ' + scope.row.last_name }}</span>
                  </router-link>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="LOST DATE" prop="created" min-width="140px" sortable="custom">
              <template slot-scope="scope">
                <span>{{ formatDate(scope.row.created) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="LOST REASON" prop="lost_reason" min-width="140px" sortable="custom">
              <template slot-scope="scope">
                <span>{{ scope.row.lost_reason }}</span>
              </template>
            </el-table-column>
            <el-table-column label="LOST PIPELINE" min-width="140px">
              <template slot-scope="scope">
                <span>{{ scope.row.from_pipeline_name }}</span>
              </template>
            </el-table-column>
            <el-table-column label="LOST STAGE" min-width="140px">
              <template slot-scope="scope">
                <span>{{ scope.row.from_pipeline_stage_name }}</span>
              </template>
            </el-table-column>
            <el-table-column label="CHECKLIST % COMPLETE" min-width="140px">
              <template slot-scope="scope">
                <span>{{ scope.row.checklist_completed_percentage }}</span>
              </template>
            </el-table-column>
            <el-table-column label="NEXT CHECKLIST ITEM" min-width="140px">
              <template slot-scope="scope">
                <span>{{ scope.row.next_checklist_item }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <seminar-surgery-footer :pagination="pagination" @changePage="changePage" @changedPageLength="changedPageLength"/>
      </div>
    </div>
  </div>
</template>

<script>
import SeminarSurgeryFooter from "./SeminarSurgeryFooter";
import {Option, Select, Table, TableColumn} from "element-ui";
import PieChart from "../../../components/Charts/PieChart";
import Helper from "@/util/globalHelpers";
import moment from "moment-timezone";
import LostPatientLineGraphModal from "@/views/Pages/Seminar2Surgery/LostPatientLineGraphModal";
import fileDownload from "@/mixins/fileDownload";

function randomScalingFactor() {
  return Math.round(Math.random() * 100);
}
export default {
  name: "LostPatients",
  props :  ['tab'],
  components: {
    SeminarSurgeryFooter,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    PieChart,
    LostPatientLineGraphModal
  },
  data(){
    return {
      loading : false,
      api_called : false,
      page_change_call : false,
      modals : {
        lostPatientsStatsModal :false,
      },
      request : {
        api: {
          firstName:'',
          lastName:'',
          sortBy:'created',
          sortByDir:'DESC'
        },
      },
      response : {
        tblLostPatients : [],
      },
      pagination : {
        currentPage : 1,
        perPage : 20,
        page:1,
        lastPage:1,
      },
      pieChart: {
        chartData: {
          labels: [
            'Lost contact with patient',
            'Denied by insurance',
            'No longer wants to have weight loss surgery',
            'Unhappy with program',
            'Does not meet criteria',
            'Out of network insurance',
            'Other'
          ],
          datasets: [{
            data: [],
            backgroundColor: [],
          }],
        },
        extraOptions: {
          responsive: true,
          legend: {
            position: 'top',
          },
          // tooltips : {
          //   enabled:false,
          // },
          animation: {
            animateScale: true,
            animateRotate: true
          },
        },
      },
      showChart : true,
      lostPatientsLineGraphData: [],
      lostPatientsLineGraphLabels: [],
      lostPatientGraphLoading: false
    }
  },
  mounted() {
      this.getLostPatients();
  },
  mixins: [
    fileDownload
  ],
  methods : {
    /**
     * Generate the graph data
     * @param lostPatients
     */
    generateGraphData(lostPatients) {
      const dataArray = [0, 0, 0, 0, 0, 0]
      for (let i = 0; i < 6; i++) {
        const dateStr = moment(this.lostPatientsLineGraphLabels[i], 'MMM YYYY').format('YYYY-MM')
        lostPatients.map(patient => {
          if (patient.created.indexOf(dateStr) >= 0)
            dataArray[i]++
        })
      }
      this.lostPatientsLineGraphData = dataArray
    },

    /**
     * Get the Lost Patients Line Graph data
     * @param timeGap
     */
    fetchLostPatientsLineGraph(timeGap) {
      let vm = this
      vm.lostPatientGraphLoading = true
      const payload = {
        timeGap
      }
      vm.$store.dispatch('_getLostPatientsLineGraph', payload)
      .then(response => {
        this.getLostPatientsLineGraphLabels(timeGap)
        this.generateGraphData(response.data.data)
      })
      .catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'Lost Patients Line Graph',
          message: message
        })
      })
      .finally(() => {
        vm.lostPatientGraphLoading = false
      })
    },

    /**
     * Export lost patients report
     */
    exportLostPatientsReport() {
      let vm = this
      const config = {
        responseType: "blob",
        params: {
          ...vm.request.api
        }
      }
      vm.$store.dispatch('_lostPatientsExcel', config)
          .then(response => {
            vm.generateExportFileDownload(response, 'Baritastic-Lost-Patients')
          })
          .catch(error => {
            const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
            vm.$notify.error({
              title: 'Lost Patients Report',
              message: message
            })
          })
          .finally(() => {
            vm.$emit('exportComplete')
          })
    },

    /**
     * Calculate and store Line Graph labels into array according to given time gap
     * @param timeGap
     */
    getLostPatientsLineGraphLabels(timeGap) {
      timeGap = timeGap.split(" ")
      let monthsArray = []
      if (timeGap[0] == 'months') {
        this.monthLabels(monthsArray)
      } else {
        this.yearLabels(timeGap, monthsArray)
      }
      this.lostPatientsLineGraphLabels = Array.from(monthsArray)
    },

    /**
     * Calculate Year Labels
     * @param timeGap
     * @param monthsArray
     */
    yearLabels(timeGap, monthsArray) {
      const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const monthsGap = this.gapInMonths(-timeGap[1])
      for (let i = (-timeGap[1] * 12) - 1; i>=0; i--) {
        let date = new Date()
        date.setMonth(date.getMonth() - i)
        monthsArray.push(labels[date.getMonth()] + ' ' + date.getFullYear())
        i = i - monthsGap
      }
    },

    /**
     * Calculate Month Labels
     * @param monthsArray
     */
    monthLabels(monthsArray) {
      let date
      const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      for (let i = 5; i >= 0; i--) {
        date = new Date()
        date.setMonth(date.getMonth() - i)
        monthsArray.push(labels[date.getMonth()] + ' ' + date.getFullYear())
      }
    },


    /**
     * Calculate gap between months according to years
     * @param years
     * @returns {number}
     */
    gapInMonths(years) {
      if (years === 1)  return 1
      else if (years === 2) return 3
      else if (years === 3) return 5
      else return 10
    },

    /**
     * Show the Lost Patient Line Graph Modal
     */
    showLostPatientsLineGraphModal() {
      this.$store.commit('toggleLostPatientsLineGraphModal', true)
      this.fetchLostPatientsLineGraph('months -6')
      this.$refs.lostPatientLineGraph.renderLineGraph()
    },

    hoverPatientInfo(row){
      let returnable = ''
      returnable = (row.hasOwnProperty('first_name') === true&& row.first_name !== '' && row.first_name != null) ? returnable+row.first_name : returnable+''
      returnable = (row.hasOwnProperty('last_name') === true&& row.last_name !== '' && row.last_name != null) ? returnable+' '+row.last_name : returnable+''
      returnable = (row.hasOwnProperty('email') === true && row.email !== '' && row.email != null) ? returnable+', '+row.email : returnable+''
      returnable = (row.hasOwnProperty('phone') === true && row.phone !== '' && row.phone != null) ? returnable+', '+row.phone : returnable+''
      if (row.hasOwnProperty('dob') === true && row.dob !== '' && row.dob != null){
        if (!isNaN(Date.parse(row.dob))){
          const date = new Date(row.dob)
          returnable = returnable+', '+Helper.extractAndFormatDate(date.toDateString(),false)
        }
      }else {
        returnable = returnable+''
      }
      returnable = (row.hasOwnProperty('mrn_number') === true && row.mrn_number !== '' && row.mrn_number != null) ? returnable + ', ' + row.mrn_number : returnable + ' '
      return returnable
    },

    filterChanged(){
      this.pagination.page = 1;
      this.getLostPatients();
    },

    getDate(date){
      if (!date) return ''
      return Helper.extractAndFormatDate((new Date(date)).toDateString(),true)
    },

    changePage(page){
      if(this.pagination.page != page) {
        this.pagination.page = page;
        this.getLostPatients();
      }
    },

    changedPageLength(pageLength) {
      this.pagination.page = 1;
      this.pagination.perPage = pageLength;
      this.getLostPatients();
    },

    getLostPatients(){
      let vm = this;
      vm.loading = true;
      axios.get(this.$store.getters.getBaseUrl + '/api/seminar2surgery/lost-patients',
        {
          params: {
            ...vm.request.api,
            page: vm.pagination.page,
            perPage: vm.pagination.perPage
          }
        }
      ).then((response) => {
        vm.response.tblLostPatients = [];
        vm.response.tblLostPatients = [...response.data.data.data];
        vm.pagination.currentPage = response.data.data.current_page;
        vm.pagination.lastPage = response.data.data.last_page;
        vm.pagination.perPage = response.data.data.per_page;
        vm.pagination.page = response.data.data.current_page;
        vm.pagination.total = response.data.data.total;
        vm.loading = false;
        vm.api_called = false;
      }).catch(error => {
        console.log(error.response);
      });
    },

    toggleLostPatientsStatsModal(){
      this.getLostPatientsStats();
      this.modals.lostPatientsStatsModal = !this.modals.lostPatientsStatsModal;
    },

    getLostPatientsStats(){
      let vm = this;
      axios.get(this.$store.getters.getBaseUrl + '/api/seminar2surgery/lost-patients-stats'
      ).then((response) => {
        if (response.data.data.chart.counts.every(x => x === 0)){
          vm.showChart = false;
        }
        vm.pieChart.chartData = {
          datasets: [{
            data: response.data.data.chart.counts,
            backgroundColor: [
              '#5E72E4', '#FF6421', '#FE8C19', '#94B527', '#56671f', '#1b4b2b', '#68C0EF'
            ],
          }],
        };
        console.log(vm.pieChart.chartData);
      }).catch(error => {
        console.log(error.response);
      });
    },

    formatDate(oldDate) {
      let date = '';
      if(oldDate){
        date = moment(oldDate).format('MMM D, YYYY');
      }
      return date;
    },

    sortMethod({prop,order})  {
      if(order){
        this.request.api.sortBy = prop;
        this.request.api.sortByDir = order == 'ascending' ? 'ASC' : 'DESC';
      }
      else {
        this.request.api.sortBy = "created";
        this.request.api.sortByDir = 'DESC';
      }
      this.pagination.page = 1;
      this.getLostPatients();
    }
  },
  watch : {

  }
}
</script>

<style scoped>

.btn-lost-patients-modal {
  float: right;
  background: #2f597a 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #32325D26;
  border-radius: 6px;
  opacity: 1;
  text-align: center;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #FFFFFF;
  padding: 3px 12px 0px 0px;
}

.btn-lost-patient-line-graph {
  float: right;
  background: #2f597a 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #32325D26;
  border-radius: 6px;
  text-align: center;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #FFFFFF;
  padding-bottom: 12px;
}

.ico-btn-lost-patients-modal {
  margin-bottom: 3px;
}

#pie-chart {
  height: 200px !important;
  width: 200px !important;
  padding-left: 0px;
}

.doughnut-chart-row {
  height: 250px;
  width: 600px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  word-break: break-word;
}

.doughnut-labels-wrapper {
  margin: 0;
  padding: 30px;
  overflow-y: auto;
}

.doughnut-labels {
  font-size: 14px;
  margin: 0;
  padding: 0 0 3px 0;
  position: relative;
}

.doughnut-labels::before {
  content: '';
  position: absolute;
  left: -15px;
  height: 15px;
  width: 15px;
  top:45%;
  transform: translate(-50%, -50%);
  border-radius: 100px;
}
.doughnut-labels::after {
  transform: translate(-50%, -50%);
}

#breakfast-circle::before {
  border: 4px solid #5E72E4;
}

#lunch-circle::before {
  border: 4px solid #FF6421;
}

#dinner-circle::before {
  border: 4px solid #FE8C19;
}

#snacks-circle::before {
  border: 4px solid #94B527;
}

#not-meet-criteria-circle::before {
  border: 4px solid #56671f;
}

#network-out-circle::before {
  border: 4px solid #1b4b2b;
}

#others-circle::before {
  border: 4px solid #68C0EF;
}

</style>

<style>

.modals .lost-patients-stats-modal .modal-content {
  width: 600px !important;
}
</style>
